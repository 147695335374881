import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/src/app/app/_components/datadog-init.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/src/app/app/_shared/components/global/auth-prompt-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/src/app/app/_shared/components/global/azure-insights-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TermsAcceptancePrompt"] */ "/usr/src/app/app/_shared/components/global/terms-acceptance-prompt.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TrpcProvider"] */ "/usr/src/app/app/_shared/components/global/trpc-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LocalizationProvider"] */ "/usr/src/app/app/_shared/components/localization-provider.tsx");
;
import(/* webpackMode: "eager" */ "/usr/src/app/app/_shared/styles/globals.css");
;
import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/next/font/local/target.css?{\"path\":\"app/_shared/components/global/themed-html.tsx\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../../assets/font/gilroyoffice-light.woff2\",\"weight\":\"400\",\"style\":\"normal\"},{\"path\":\"../../assets/font/gilroyoffice-regular.woff2\",\"weight\":\"600\",\"style\":\"normal\"},{\"path\":\"../../assets/font/gilroyoffice-bold.woff2\",\"weight\":\"900\",\"style\":\"normal\"}]}],\"variableName\":\"gilroyoffice\"}");
